.tdtitle {
  cursor: pointer;
}

.tdtitle:hover {
  color: #f6a75c;
}

.table .handle_inner span:last-of-type {
  color: #888888;
}

.table .handle_inner span:last-of-type:hover {
  color: #f6a75c;
}

.center {
  text-align: center;
}

.dialog h1 {
  margin-bottom: .2rem;
  display: flex;
}

.dialog h1 .label {
  width: 0.6rem;
  flex-shrink: 0;
  font-size: 0.2rem;
}

.dialog h1 .txt {
  flex: 1;
  font-size: 0.2rem;
}

.dialog .content {
  display: flex;
}

.dialog .content .label {
  width: 0.6rem;
  flex-shrink: 0;
  font-size: 0.2rem;
}

.dialog .content .txt {
  flex: 1;
  max-height: 4rem;
  overflow-y: auto;
  font-size: 0.16rem;
}

.ant-modal-root :deep(.ant-btn) {
  display: none;
}

.ant-modal-root :deep(.ant-btn-primary) {
  display: inline-block;
}